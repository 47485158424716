import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';

const NikkeGuidesOutpostPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Outpost</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_outpost.png"
            alt="Outpost"
          />
        </div>
        <div className="page-details">
          <h1>Outpost</h1>
          <h2>A guide to manage your Outpost.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
      <div className="page-content">
        <SectionHeader title="Outpost" />
        <p>
          After clearing Chapter 2 stage, following the story will unlock a new
          area called Outpost. The Outpost will be the main base of your
          operations from here onward, and where you will interact with your
          various NIKKEs you have gained from the gacha.
        </p>
        <StaticImage
          src="../../../images/nikke/generic/outpost_1.jpg"
          alt="Outpost"
        />
        <p>
          At the beginning, only a few areas will be available while other areas
          will be unlocked by progressing through the story.
        </p>
        <p>These are:</p>
        <ul>
          <li>Command Center, unlocked by default</li>
          <li>Outpost Defense, unlocked by default</li>
          <li>Infrastructure Core, unlocked by default</li>
          <li>Bulletin Board, unlocked by default</li>
          <li>Tactics Academy, unlocked by default</li>
          <li>Synchro Device, Campaign Stage 4-15</li>
          <li>Recycling Room, Campaign Stage 5-10</li>
        </ul>
        <p>
          Now let us break this down into sections and explain what each area of
          the Outpost does.
        </p>
        <SectionHeader title="Command Center" />
        <StaticImage
          src="../../../images/nikke/generic/outpost_2.jpg"
          alt="Outpost"
        />
        <p>
          The Command Center will be your main base of operations, where any
          story progression will take place in.
        </p>
        <p>
          Entering the command center will take you to your private room and
          office. Without clicking on any of the menus you can look around your
          room, although sadly you cannot see the Counters taking a shower.
        </p>
        <StaticImage
          src="../../../images/nikke/generic/outpost_5.jpg"
          alt="Outpost"
        />
        <p>Now let us explain what each menu does in your office.</p>
        <h5>Recollection</h5>
        <StaticImage
          src="../../../images/nikke/generic/outpost_3.jpg"
          alt="Outpost"
        />
        <p>
          The Recollection menu opens up 2 tabs, one for Main and one for Brief
          encounter. Main tab menu allows you to view the story events again
          from the story mode.
        </p>
        <p>
          Brief encounters are conversations you can do with various NIKKE to
          gain more insight into their daily lives as well as be awarded with
          gifts that can increase their bond level. Any NIKKE’s who have a brief
          encounter open will have a chat mark above the building.
        </p>
        <p>
          Outpost Stamina is required to view these events with the default
          number of stamina you have starting out being 2. You can increase the
          amount of Outpost stamina through Infrastructure core, which will be
          explained later. Outpost stamina is limited and recharges weekly, so
          do keep that in mind in which NIKKE you wish to have a brief encounter
          with. The brief encounters will not reset so don’t worry about missing
          out on any brief encounters!
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <h5>Advise</h5>
        <StaticImage
          src="../../../images/nikke/generic/outpost_4.jpg"
          alt="Outpost"
        />
        <p>
          Advise option opens up the Bond system menu, where you can further
          develop your relationship with NIKKEs to improve their affection
          towards you as well as increase their stats passively. You can also
          view their character stories which give more insight to the said
          NIKKE. If you are having a hard time searching for a particular NIKKE,
          you can sort the menu to showcase only NIKKEs from their manufacturer
          through the tabs.
        </p>
        <p>
          When you click on a specific NIKKE, it will open up a new menu where
          you can see their current rank. Each level requires a certain amount
          of points that can be earned through gifts or the Advise option. You
          can also view their character stories that unlock after certain ranks
          are achieved by clicking View Episode. If you want to keep track of
          the NIKKE you are currently bonding with, you can click on the star
          option to bookmark them. Bookmarked NIKKEs will always be at the top
          of the list so you don’t have to search for them anymore!
        </p>
        <p>
          Gift button allows you to give various gifts that increase their bond
          level, while various NIKKE will have certain preferences to what gifts
          they receive. Gifts are earned through outpost defense and brief
          encounters.
        </p>
        <p>
          Advise button will allow you to interact with your NIKKE and they will
          ask you questions in which you can answer with 2 options. Choosing the
          right option will give you 100 bond points, while the wrong option
          will only give you 50 points. Do note you can only advise one per
          NIKKe up to 5 NIKKEs daily. Remember to think about the Waifu you are
          advising and know what their interests are!
        </p>
        <p>
          Disclaimer from Central Government: You cannot marry them nor do lewds
          act with them. Please be a responsible commander and have the war
          effort in your best interests.
        </p>
        <h5>Lost Relic</h5>
        <StaticImage
          src="../../../images/nikke/generic/outpost_6.jpg"
          alt="Outpost"
        />
        <p>
          Lost Relics menu will open up the collectibles section where you can
          view various lore hidden in the story mode stages that you have
          collected over time. Once you have completed the set, you can view the
          history of the old world!
        </p>
        <h5>Jukebox</h5>
        <StaticImage
          src="../../../images/nikke/generic/outpost_7.jpg"
          alt="Outpost"
        />
        <p>
          Jukebox will open the OST menu, where you can listen to the various
          soundtracks of the game, with certain soundtracks hidden throughout
          the combat field in story mode. By clicking on the music note to a
          particular track, you can make that soundtrack be the main music
          playing in the commander room. However sadly this does not apply to
          the Outpost itself or lobby screen as there is no option to do so yet.
          OST tracks are found in both Normal and Hard mode, so start hunting
          down those elusive shiny sparks Commander!
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Outpost Defense" />
        <StaticImage
          src="../../../images/nikke/generic/outpost_8.jpg"
          alt="Outpost"
        />
        <p>
          Outpost Defense is the idle system of Goddess of Victory: Nikke. Here,
          you will accumulate offline progression rewards such as equipment,
          core dust,credit, battle data, and your commander level EXP. You can
          even earn 100 gems every time you level up your Outpost defense!
        </p>
        <p>
          Now let’s how we can increase those levels. That is done through
          completing story stages. With every story stage you complete, you will
          progress towards increasing the level of Outpost Defense. Outpost
          Defense level will increase after you have completed 5 story stages,
          and as the level grows you will earn more rewards and better items
          through offline progression.
        </p>
        <StaticImage
          src="../../../images/nikke/generic/outpost_9_1.jpg"
          alt="Outpost"
        />
        <p>
          Wipeout is a function that allows you to instantly gather resources
          that is equivalent to 120 mins real time. You can do this 11 times,
          with the first time being free daily.
        </p>
        <p>
          Once you have used your free wipeout, you will have to use gems in
          order to continue wipeout, and the gem cost will increase with each
          use. Be careful of spending too much commander!
        </p>
        <SectionHeader title="Infrastructure Core" />
        <StaticImage
          src="../../../images/nikke/generic/outpost_10.jpg"
          alt="Outpost"
        />
        <p>
          Infrastructure Core is a system that allows you to earn various perks
          as you level up and unlock Chipsets. Each Chipset contains a bonus
          perk to the Outpost and gives an additional general shop reset. Perks
          include Stamina to have more brief encounters, unlocking
          Auto-Dispatch, Dispatch-all and many other perks.
        </p>
        <p>
          Infrastructure points are earned through the achievements menu, where
          completing various achievements will give you certain points that will
          go to leveling the Infrastructure core. Be sure to check the
          achievements occasionally and see what achievements you can clear to
          continue leveling the Infrastructure core!
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Bulletin Board" />
        <StaticImage
          src="../../../images/nikke/generic/outpost_11.jpg"
          alt="Outpost"
        />
        <p>
          Bulletin Board refreshes daily, and gives you various missions that
          you can dispatch your NIKKEs to complete them passively after a
          certain amount of time, allowing you to earn various rewards after
          completion.
        </p>
        <p>
          Each mission will have a different requirement, with most of the early
          missions based on the rarity of NIKKEs. As you level up your
          Infrastructure Core, you will increase your dispatch range to have
          better rewards available at the bulletin board, where the requirements
          will also increase. Eventually, you will start to see requirements
          based on Manufacturers, so make sure you have a good roster to send
          out NIKKEs!
        </p>
        <SectionHeader title="Tactics Academy" />
        <StaticImage
          src="../../../images/nikke/generic/outpost_12.jpg"
          alt="Outpost"
        />
        <p>
          Tactics Academy is where you unlock various boosts to game modes as
          well as unlock various upgrades to the Outpost.
        </p>
        <p>
          Each class has certain lessons you can pay for, with higher lessons
          costing more credit. To unlock each lesson, you also must have a
          certain R rarity NIKKE in order to do so. Higher lessons will require
          certain SR rarity characters in order to progress further.
        </p>
        <StaticImage
          src="../../../images/nikke/generic/outpost_13.jpg"
          alt="Outpost"
        />
        <p>
          Starting from Lesson 2-1 you will notice a new requirement in order to
          progress through the lessons. You now have to construct certain
          buildings in the outpost in order to complete and progress further
          into the lessons.
        </p>
        <p>
          In order to first construct buildings, you must first find the
          specific construction building blueprint in the story mode campaign
          stages. If you missed any, you can always go back to the previous
          campaign story stages to look for them. Building blueprints are always
          found in Normal mode, all the way up to Chapter 10. Check our guide
          for an easier time finding them!
        </p>
        <p>
          Once you have obtained the blueprints, buildings can be constructed
          anywhere you see a + symbol hovering certain empty spaces. Building
          spaces will be limited at the start, but you can increase the building
          space once you progress through Tactics academy.
        </p>
        <StaticImage
          src="../../../images/nikke/generic/outpost_14.jpg"
          alt="Outpost"
        />
        <p>
          Buildings with requirements needed to progress Tactics Academy will be
          noted in the construction menu so don’t worry about having to switch
          back and forth from the Tactics Academy!
        </p>
        <p>
          Once you have chosen a space to construct your building, you’ll notice
          it will take time to construct said building. It will take 5 seconds
          to finish building the structure, and afterwards, you must click on
          the constructed building in order to fully finish it.
        </p>
        <p>
          Once you unlock more spaces, you can easily click on the move building
          in order to move buildings to other empty spaces in order to organize
          or decorate your outpost however to your liking!
        </p>
        <SectionHeader title="Synchro Device" />
        <StaticImage
          src="../../../images/nikke/generic/outpost_15.jpg"
          alt="Outpost"
        />
        <p>
          After beating campaign stage 4-15 you will unlock a new feature in the
          Outpost called Synchro Device.
        </p>
        <p>
          Synchro Device is a system that will allow you to boost a NIKKE’s
          level to match the highest 5 leveled characters you have. This makes
          it so you don’t have to worry about leveling any new NIKKE’s you
          obtain that may suit your preferences and needs!
        </p>
        <p>
          Now this may get confusing so pay attention! The current 5 highest
          leveled NIKKEs will be shown in the menu, where the lowest level of
          the NIKKE will be chosen in order to determine the current level you
          can boost your other NIKKEs to. If they are all equal level, then the
          equal level instead will be chosen.
        </p>
        <p>
          By clicking on the currently opened spaces, you can place NIKKEs here
          to boost their level to the current level shown. Do note their skills
          will not be affected and will simply increase their levels.
        </p>
        <p>
          You can freely remove the NIKKE you have synced, however in doing so,
          the slot will go into a 4 hour cooldown timer. Removing the NIKKEs
          will reset their level back to where they were originally, so don’t
          worry about losing any progress.
        </p>
        <p>
          You can increase further slots for free by progressing through the
          Tactics Academy or you can pay 500 gems in order to unlock new slots.
        </p>
        <p>
          After 5 NIKKEs have reached a maximum level of 200, the synchro device
          will change and become Enhanced Synchro device. At this point all
          NIKKEs placed in Synchro device will be leveled to 200 by default, and
          you may level the NIKKEs further from the max limit of 200 to a
          maximum of 462.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Recycling Room" />
        <StaticImage
          src="../../../images/nikke/generic/outpost_16.jpg"
          alt="Outpost"
        />
        <p>
          Recycling room is a feature unlocked after beating Campaign Stage
          5-10, and is found in the Outpost.
        </p>
        <p>
          The Recycling room is a feature where you can further upgrade stats of
          NIKKEs, depending on the choices of your cores.
        </p>
        <p>
          <strong>
            Before we start delving into the various cores, we would like to
            give a huge disclaimer on the Recycling room.
          </strong>
        </p>
        <p>
          The currency needed to buy the tokens to upgrade the Recycling room is
          called Body Label Tokens and they can be earned through the Gacha
          system. Each duplicate character you earn gives you a certain amount
          of Body label tokens based on rarity. As such, this is more of a
          system for those who are willing to spend on the gacha system while
          Free to play players will have to earn the currencies passively
          through Outpost defense.
        </p>
        <p>
          Body label tokens can be used in the Body Label shop to buy various
          tokens to level the cores in the Recycling room. Each core has its own
          currency needed, so please do be careful in what you are spending, as
          you will have to roll the gacha if you wish to have more body label
          tokens.
        </p>
        <StaticImage
          src="../../../images/nikke/generic/outpost_17.jpg"
          alt="Outpost"
        />
        <p>
          In the middle of the UI, is a big core called General research.
          General research increases every NIKKEs HP and DEF and will be the
          main core you will focus on leveling.
        </p>
        <p>
          By clicking on the core, you will open a new menu to allow you to
          level the core itself, which will require its own currency in order to
          level up.
        </p>
        <StaticImage
          src="../../../images/nikke/generic/outpost_18.jpg"
          alt="Outpost"
        />
        <p>
          The currency used for General Research is called RE-Energy and can be
          bought from the body label shop. It costs 300 body label tokens for 1
          token, and you will need 10 of them in order to increase 1 level in
          the general core.
        </p>
        <p>
          By leveling General Research to level 5, you will be able to unlock
          the class cores which give HP and DEF. Every NIKKE falls into 3
          classes, so choose wisely in which class you prefer to focus on.
          Certain levels will require a certain General Research level so make
          sure you don’t fall behind on your General Research!
        </p>
        <p>
          Class currency is needed to level the class cores, which can be bought
          from the body label shop for 400 tokens per 1 class token. As this is
          expensive, please choose carefully on what you spend.
        </p>
        <p>
          By leveling General Research to level 10, you will now be able to
          unlock the Manufacturer cores. Manufacturer cores give ATK and DEF,
          which make them good for all commanders. Every NIKKE is produced by a
          manufacturer, and it will depend on who their manufacturer is in order
          to get the boost from the Manufacturer core. As ATK and DEF are ever
          important, we recommend focusing a Manufacturer core first before
          leveling the class cores.Certain levels will require a certain General
          Research level so make sure you don’t fall behind on your General
          Research!
        </p>
        <p>
          Manufacturer cores are leveled up through their own currency, which
          cost 400 tokens. They can also be bought from the body label shop for
          400 tokens per 1 class token. As this is expensive, please choose
          carefully on what you spend.
        </p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
    </DashboardLayout>
  );
};

export default NikkeGuidesOutpostPage;

export const Head: React.FC = () => (
  <Seo
    title="Outpost | NIKKE | Prydwen Institute"
    description="A guide for the Outpost in Goddess of Victory: NIKKE."
  />
);
